import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import './SocialMedia.scss';

function SocialMedia({ siteurl, type, title, pathName, wordcount, images, basePath}) {
  const [siteprotocol,setSiteProtocol] = useState('')
  const encodedTitle = encodeURIComponent(title);
  const imageOptimum = '?fm=webp&q=100';
  const socialLinks = [
    {
      id: 0,
      iconname: images?.linkedlnIcon?.description,
      alt: images?.linkedlnIcon?.description,
      prefix: process.env.GATSBY_LINKEDIN_PREFIX,
      mediaicon: images?.linkedlnIcon?.file?.url + imageOptimum,
      locId: 'linkedlnIcon',
    },
    {
      id: 1,
      iconname: images?.twitterIcon?.description,
      alt: images?.twitterIcon?.description,
      prefix: process.env.GATSBY_TWITTER_PREFIX,
      mediaicon: images?.twitterIcon?.file?.url + imageOptimum,
      locId: 'twitterIcon',
    },
    {
      id: 2,
      iconname: images?.facebookIcon?.description,
      alt: images?.facebookIcon?.description,
      prefix: process.env.GATSBY_FACEBOOK_PREFIX,
      mediaicon: images?.facebookIcon?.file?.url + imageOptimum,
      locId: 'facebookIcon',
    },
    {
      id: 3,
      iconname: images?.emailIcon?.description,
      alt: images?.emailIcon?.description,
      prefix: process.env.GATSBY_EMAIL_PREFIX,
      mediaicon: images?.emailIcon?.file?.url + imageOptimum,
      locId: 'emailIcon',
    },
  ];

  useEffect(()=>{
    setSiteProtocol(window.location.protocol)
  },[])

  return (
    <Grid item container xl={6} lg={6} md={12} sm={12} xs={12} className="social-container">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="icon-text">
        {socialLinks?.map((icon) =>
          icon.iconname !== images?.emailIcon?.description ? (
            <a
              href={`${icon?.prefix}${siteprotocol}//${siteurl}/${basePath}/${pathName}`}
              locId={icon.iconname}
              target="_blank"
            >
              <img
                src={icon?.mediaicon}
                className="social-imgs"
                alt={icon.alt}
                title={icon.iconname}
                locId={icon.locId}
              />
            </a>
          ) : (
            <a
              href={`${icon?.prefix} ${type} : ${encodedTitle} ${process.env.GATSBY_EMAILBODY} ${siteprotocol}//${siteurl}/${basePath}/${pathName}`}
              locid={icon.iconname}
              target="_blank"
            >
              <img
                src={icon?.mediaicon}
                className="social-imgs"
                alt={icon.alt}
                title={icon.iconname}
                locId={icon.locId}
              />
            </a>
          )
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="word-text">
          <p className="word-caption" locId="wordCount">
            Word count: {wordcount}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SocialMedia;
